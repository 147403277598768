<template>
  <div class="pagesuscribete">
    <MenuMovil></MenuMovil>
      <!--Contenido principal-->
      <div class="container">
        <div class="row" style="min-height: 100vh;">
          <!-- Menú lateral solo PC-->
          <MenuPc :filter="$root.filter"></MenuPc>
          <!-- Barra central-->
          <div class="col-12 col-md-8 col-lg-6" style="padding-bottom: 40px">
            <div class="fijar-barra">
              <NavTop></NavTop>
            </div>
            <div v-show="!$root.show_loader">
              <div class="row" style="padding: 4px;" v-if="!licencia.licencia">
                <div class="col-12">
                  <span class="titulo">Hazte premium y colabora por cliptter</span>
                </div>
                <div class="col-12">
                  <span class="detalle">No mas publi</span>
                </div>
                <div class="col-12">
                  <span class="detalle">No mas tiempo de espera</span>
                </div>
                <div class="col-12">
                  <span class="detalle">Sin compromisos. Podrás cancelarlo cuando te de la gana.</span>
                </div>


                <div class="col-12" style="padding:0; text-align:center; margin:10px auto;">
                  <button style="color:#000; margin:10px;" :disabled="{'disabled' : !$root.isLogued()}" @click="goToSuscripcion(1)">1.99€ - 1 mes</button>
                  <button style="color:#000; margin:10px;" :disabled="{'disabled' : !$root.isLogued()}" @click="goToSuscripcion(2)">5.99€ - 6 meses</button>
                  <button style="color:#000; margin:10px;" :disabled="{'disabled' : !$root.isLogued()}" @click="goToSuscripcion(3)">9.99€ - 12 meses</button>
                </div>

                <div v-if="!$root.isLogued()" style="cursor: pointer; text-align:center; margin:10px auto;">
                  <span class="detalle">Para hacerte premium tienes que inciar sesión</span>
                  <div class="boton">
                    <div @click="$root.login()">Iniciar sesión</div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div style="margin-top:20px">
                  <h3>Tu cuenta premium está activa.</h3>
                  <div><span>Fecha de inicio: </span> <span>{{licencia.fecha_inicio}}</span></div>
                  <div><span>Fecha de fin: </span> <span>{{licencia.fecha_fin}}</span></div>
                  <div>Muchas gracias!</div>
                </div>
              </div>
            </div>
          </div>

          <!--Barra lateral derecha, solo PC-->
          <div class="d-none d-md-block col-md-3 col-lg-4" style="border-left: 1px solid #38444d; padding-top: 10px">
            <div style="position: sticky; top: 10px">
              <Tendencias/>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal para añadir-->
      <ModalAddVideo :tweet_detail="$root.tweet_detail"></ModalAddVideo>
  </div>
</template>

<script>
import PageMixin from './PageMixin'
import axios from "axios"

export default {
    name: "PageSuscribete",
    mixins: [PageMixin],
    data: () => {
        return {
          items: [],
          licencia: {
            licencia: false,
            fecha_inicio: null,
            fecha_fin: null
          },
          infiniteScroll : {
            load: true,
            page: 0,
            total_pages: 0
          }
        }
    },
    methods: {
      goToSuscripcion(plan_id) {
        if(!this.isLogued()) {
          this.$root.showAlert_logueate()
        }
        else
        {
          self = this
          self.$root.show_loader = true
          axios.get(process.env.VUE_APP_API_BASE_URL + '/subscribe' + '?plan=' + plan_id, { params: {
              url_return: null
            }
          }).then(response => {
            if(response.data.error)
            {
              self.$root.show_loader = false
              self.$root.showAlert_error(response.data.message)
            }
            else {
              window.location.href = response.data.url
            }
          })
          .catch(err => {
            console.log("Error");
            console.log(err);
            self.$root.show_loader = false
            self.$root.showAlert_error(err)
          })
          .finally(() => {

          });
        }
      }
    },
    created () {
      var self = this
      if(this.$route.query.status) {
        if(this.$route.query.status=="cancel") {
          self.$root.showAlert_error("Operación cancelada")
          self.$router.push('/suscribete').catch(()=>{});
        }
        else if(this.$route.query.status=="error") {
          self.$root.showAlert_error("Ha ocurrido un error y no se ha podido finalizar el proceso de suscripción. Si hubiera realizado el pago y su suscripción no está activa, por favor, contacte con nuestro equipo técnico en info@cliptter.com. Disculpe las molestias")
          self.$router.push('/suscribete').catch(()=>{});
        }
        else if(this.$route.query.status=="done") {
          if(this.$route.query.paypal_token) {
            self.$root.show_loader = true
            axios.get(process.env.VUE_APP_API_BASE_URL + '/subscribe_validation', { params:{
              paypal_token: this.$route.query.paypal_token
            }}).then(response => {
              if (response.status === 200) {
                if(response.data.result == 1)
                {
                  self.licencia = response.data.data_licencia
                  self.$root.showAlert_confirm(response.data.message)
                  self.$router.push('/suscribete').catch(()=>{});
                }
                else{
                  self.$root.showAlert_error("Error desconocido. Si hubiera realizado el pago y su suscripción no está activa, por favor, contacte con nuestro equipo técnico en info@cliptter.com. Disculpe las molestias")
                  self.$router.push('/suscribete').catch(()=>{});
                }
              }
            })
            .catch(err => {
              self.$root.show_loader = false
              this.$router.push(this.$route.path)
              console.log("Error");
              console.log(err);
              self.$root.showAlert_error(err)
            })
            .finally(() => {
              self.$root.show_loader = false
            });
          }
        }
      }
      else {
        self.$root.show_loader = true
        axios.get(process.env.VUE_APP_API_BASE_URL + '/subscribe_info').then(response => {
          if (response.status === 200) {
            this.licencia = response.data.data_licencia
          }
        })
        .catch(err => {
          self.$root.show_loader = false
          this.$router.push(this.$route.path)
          console.log("Error");
          console.log(err);
          self.$root.showAlert_error(err)
        })
        .finally(() => {
          self.$root.show_loader = false
        });
      }
    }
  }
</script>
