import PageInicio from "./components/PageInicio.vue"
import PageDescubre from "./components/PageDescubre.vue"
import PageSecciones from "./components/PageSecciones.vue"
import PageTutorial from "./components/PageTutorial.vue"
import PageCliptter from "./components/PageCliptter.vue"
import PageCookies from "./components/PageCookies.vue"
import PagePrivacidad from "./components/PagePrivacidad.vue"
import PageSuscribete from "./components/PageSuscribete.vue"

const routes = [
  { path: '/', component: PageInicio, name: 'PageInicio' },
  { path: '/descubre', component: PageDescubre, name: 'PageDescubre' },
  { path: '/descubre/:buscar', component: PageDescubre, name: 'PageDescubre' },
  { path: '/autor/:autor', component: PageDescubre, name: 'PageAutor' },
  { path: '/autor/:autor/:buscar', component: PageDescubre, name: 'PageAutor' },
  { path: '/tema/:tema_url', component: PageDescubre, name: 'PageTema' },
  { path: '/tema/:tema_url/:buscar', component: PageDescubre, name: 'PageTema' },
  { path: '/video/:id_video', component: PageDescubre, name:'PageVideo'},
  { path: '/favoritos', component: PageDescubre, name: 'PageFavoritos' },
  { path: '/secciones', component: PageSecciones, name: 'PageSecciones' },
  { path: '/tutorial', component: PageTutorial, name:'PageTutorial'  },
  { path: '/cliptter', component: PageCliptter, name:'PageCliptter'  },
  { path: '/cookies', component: PageCookies, name:'PageCookies'  },
  { path: '/privacidad', component: PagePrivacidad, name:'PagePrivacidad'},
  { path: '/suscribete', component: PageSuscribete, name:'PageSuscribete'}
];

export default routes;
