<template>
  <div>
    <!-- <div class="tarjeta">
      <div class="tarjeta-titulo">Tendencias</div>
      <div v-if="$root.tendencias.length > 0">
        <div v-for="tendencia in $root.tendencias" :key="tendencia.id_tendencia" :data-index="tendencia.id_tendencia" class="tarjeta-opcion" @click="filtrarTendencia(tendencia.tendencia)">{{tendencia.tendencia}}</div>
      </div>
    </div> -->
    <div style="margin-top:50px;font-size:20px; text-align:center;">
      ¿Te gusta cliptter?
      <br>
      <div><span @click="$root.showModalDonar()" class="donacion">Haz tu donación </span><font-awesome-icon icon="donate"/></div>
    </div>
    <div class="opcion-menu">
      <div class="row">
        <ul>
        <li class="menu_derecho" style="margin-top:50px;" @click="$root.setPage('PageCliptter')">¿Que es Cliptter?</li>
        <li class="menu_derecho" @click="$root.setPage('PageCookies')">Política de Cookies</li>
        <li class="menu_derecho" @click="$root.setPage('PagePrivacidad')">Política de privacidad</li>
        <li class="menu_derecho" @click="$root.showNuevasCondiciones()">Novedades</li>
        </ul>
      </div>
    </div>
    <!-- <iframe src="//a.exdynsrv.com/iframe.php?idzone=4529592&size=300x250" width="300" height="250" scrolling="no" marginwidth="0" marginheight="0" frameborder="0"></iframe> -->
  </div>
</template>

<script>
import axios from "axios"

export default {
    name: "Tendencias",
    data: () => {
        return {

        }
    },
    methods: {
      filtrarTendencia(tendencia) {
        $('#sidebar').removeClass('active');
        this.$root.setPage("PageDescubre", tendencia)
      }
    },
    mounted: function () {
      this.$root.getTendencias()
    }
}
</script>
<style>
  .menu_derecho {
    font-size: 18px;
    margin-bottom:20px;
    font-weight: bold;
    cursor: pointer;
    list-style: none;
  }
  .jconfirm-title {
    width:100%
  }
  .donacion {
    text-decoration: underline;
    cursor:pointer;
  }
</style>
