<template>
<div id="div_loader" style="height:100%;display: flex;
align-items: center;
justify-content: center; flex-direction:column; text-align:center;     position: absolute;
    top: 0;
    width: 100%;">
    <div class="logo_container" >
      <div style="margin-top:50px;font-size:20px; text-align:center; margin-bottom:20px;">
        ¿Te gusta cliptter?
        <br>
        <div><span @click="$root.showModalDonar()" class="donacion">Haz tu donación </span><font-awesome-icon icon="donate"/></div>
      </div>
      <half-circle-spinner
          :animation-duration="1000"
          :size="60"
          color="#00a478"
           style="margin:0 auto;" />
           <div style="margin:0 auto; margin-top:50px;">{{ countDown }}</div>
    </div>
  </div>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners'

export default {
    name: "VideoPublicidad",
    components: {
      HalfCircleSpinner
    },
    data: () => {
        return {
          countDown : 10,
          contador : null
        }
    },
    methods: {
      countDownTimer() {
        var self = this;
          if(this.countDown > 0) {
              this.contador = setTimeout(() => {
                  self.countDown -= 1
                  self.countDownTimer()
              }, 1000)
          }
          else if(this.countDown <= 0){
            setTimeout(() => {
                self.$parent.show_modal_donar = false
            }, 500)
          }
      },
      cargarVentana() {

      },
    },
    mounted: function () {
      this.countDownTimer();

    },
    beforeDestroy: function () {
      clearTimeout(this.contador);
    }
}
</script>
