<template>
  <div class="row barra-top" style="padding: 5px; border-bottom: 1px solid #38444d">

    <div class="col-md-12" style="padding-right: 0; padding-left: 0; padding-top: 3px">
      <div class="imageLogoMobile" id="sidebarCollapse" :style="{ 'background-image': 'url(' + icon_logo + ')'}"
        style="margin-top: -3px; margin-right: 8px; float: left; background-size: 70% 70%;">
      </div>
      <h1 style="font-size: 22px; margin-bottom: 0; margin-top: 2px; float:left; ">{{getTitle()}}</h1>

      <div style="float:right; display:inline-flex;" v-if="$route.name == 'PageDescubre' || $route.name == 'PageFavoritos' || $route.name == 'PageAutor' || $route.name == 'PageTema'">
        <div style="display:flex; flex-direction:column; justify-content:center; align-items: center;">
          <img :title="$root.tipoVista.tipo" class="icon_tipo_lista" :src="($root.tipoVista.tipo == 'listado') ? icon_orden_galeria_seleccionado : icon_orden_listado_seleccionado" @click="changeTipoVista()"/>
        </div>
      </div>

      <!-- <div style="float:right; display:inline-flex;">
        <div style="display:flex; flex-direction:column; justify-content:center; align-items: center;">
          <img title="Cortos" class="icon_duracion" :src="($root.filter.duracion == 'cortos' || $root.filter.duracion == 'todos') ? icon_duracion_cortos_seleccionado : icon_duracion_cortos" @click="changeFiltroDuracion('cortos')"/>
          <span style="font-size:12px">Cortos</span>
        </div>
        <div style="display:flex; flex-direction:column; justify-content:center; align-items: center;">
          <img title="Largos" class="icon_duracion" :src="($root.filter.duracion == 'largos' || $root.filter.duracion == 'todos') ? icon_duracion_largos_seleccionado : icon_duracion_largos" @click="changeFiltroDuracion('largos')"/>
          <span style="font-size:12px">Largos</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import icon_logo from '@/assets/images/logo.png'

import icon_duracion_cortos from '@/assets/images/duracion_cortos.png'
import icon_duracion_cortos_seleccionado from '@/assets/images/duracion_cortos_seleccionado.png'
import icon_duracion_largos from '@/assets/images/duracion_largos.png'
import icon_duracion_largos_seleccionado from '@/assets/images/duracion_largos_seleccionado.png'

import icon_orden_listado_seleccionado from '@/assets/images/orden_listado_seleccionado.png'
import icon_orden_galeria_seleccionado from '@/assets/images/orden_galeria_seleccionado.png'
export default {
    name: "NavTop",
    data: () => {
        return {
          icon_logo,
          icon_orden_listado_seleccionado,
          icon_orden_galeria_seleccionado,
          icon_duracion_cortos,
          icon_duracion_cortos_seleccionado,
          icon_duracion_largos,
          icon_duracion_largos_seleccionado
        }
    },
    methods: {
      changeFiltroDuracion(duracion) {
        if(duracion=='cortos' && this.$root.filter.duracion == 'largos')
          this.$root.filter.duracion='todos'
        else if(duracion=='largos' && this.$root.filter.duracion == 'cortos')
          this.$root.filter.duracion='todos'
        else {
          if(duracion=='cortos')
            this.$root.filter.duracion='largos'
          else {
            this.$root.filter.duracion='cortos'
          }
        }
      },
      changeTipoVista() {
        if(this.$root.tipoVista.tipo == "listado")
          this.$root.tipoVista.tipo="galeria"
        else
          this.$root.tipoVista.tipo="listado"
      },
      getTitle() {
        switch (this.$route.name) {
          case "PageInicio":
            return 'Inicio'
          case "PageDescubre":
            return 'Descubre'
          case "PageAutor":
            return this.$root.filter.autor.user
          case "PageTema":
            return this.$root.filter.tema.tema
          case "PageFavoritos":
            return 'Favoritos'
          case "PageSecciones":
            return 'Secciones'
          case "PageTutorial":
            return 'Tutorial'
          case "PageSuscribete":
            return 'Suscríbete'
        }
      }
    },
    mounted: function () {
      this.$nextTick(() => {
        $('#sidebarCollapse').on('click', function () {
          $('#sidebar').toggleClass('hide');
        });
        $("html").click(function () {
          $('#sidebar').addClass('hide');
        });
        $('#sidebar').click(function (e) {
          e.stopPropagation();
        });
        $('#sidebarCollapse').click(function (e) {
          e.stopPropagation();
        });
      })
    }
}
</script>
