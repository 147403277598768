<template>
  <transition name="fade">
    <div id="div_loader" v-if="$root.show_loader">
      <div class="logo_container">
        <half-circle-spinner
            :animation-duration="1000"
            :size="60"
            color="#00a478"
          />
      </div>
    </div>
  </transition>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners'

export default {
  name: "Loader",
  components: {
    HalfCircleSpinner
  },
  data: () => {
    return {}
  }
};
</script>
<style scoped>
#div_loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background-color: #000000;
}

.logo_container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s !important;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
