<template>
  <div>
    <MenuMovil></MenuMovil>
      <!--Contenido principal-->
      <div class="container" style="min-height: 100vh;">
        <div class="row">
          <!-- Menú lateral solo PC-->
          <MenuPc></MenuPc>
          <!-- Barra central-->
          <div class="col-12 col-md-8 col-lg-6" style="min-height: 100vh;">
            <div class="fijar-barra">
              <NavTop></NavTop>
            </div>

            <div class="row">

    <div class="col s12">

      <article id="content">
          <header style="padding-left: 0px;">
            <h2>POLÍTICA DE PRIVACIDAD</h2>
          </header>
          <div class="">
            <div class="ng-binding">
             <h3>Responsable – ¿quién es el responsable del tratamiento de los datos?</h3>

            <strong>Identidad:</strong> cliptter.com<br>
            <strong>Correo Electrónico:</strong> cliptter@gmail.com<br>
            <strong>Contacto:Administrador</strong> <br>
            <strong>Nombre del dominio:</strong> cliptter.com<br>


              <h3>Finalidades – ¿con qué finalidades tratamos tus datos?</h3>

              <p>En cumplimiento de lo dispuesto en el Reglamento Europeo 2016/679 General de Protección de Datos, te informamos de que trataremos los datos que nos facilitas para:</p>
                <ul>
                  <li>Gestionar la contratación de servicios que realice a través de la Plataforma, así como la facturación y entrega correspondiente.</li>
                  <li class="ng-binding">Remitir periódicamente comunicaciones sobre servicios, eventos y noticias relacionadas con las actividades desarrolladas por cliptter.com, por cualquier medio (teléfono, correo postal o email), salvo que se indique lo contrario o el usuario se oponga o revoque su consentimiento.</li>
                     <li>Remitir información comercial y / o promocional relacionada con el sector de servicios contratados y valor añadido para usuarios finales, salvo que se indique lo contrario o el usuario se oponga o revoque su consentimiento.</li>
                        <li>Dar cumplimiento a las obligaciones legalmente establecidas, así como verificar el cumplimiento de las obligaciones contractuales, incluía la prevención de fraude.</li>
                           <li>Cesión de datos a organismos y autoridades, siempre y cuando sean requeridos de conformidad con las disposiciones legales y reglamentarias.</li>
                </ul>





              <h3>Categorías de datos – ¿Qué datos tratamos?</h3>

              <p class="ng-binding">Derivada de las finalidades antes mencionadas, en cliptter.com  gestionamos las siguientes categorías de datos:</p>
              <ul>
                <li>Datos identificativos</li>
                <li>Metadatos de comunicaciones electrónicas</li>
                <li class="ng-binding">Datos de información comercial. En caso de que el usuario facilite datos de terceros, manifiesta contar con el consentimiento de estos y se compromete a trasladarle la información contenida en esta cláusula, eximiendo a cliptter.com de cualquier responsabilidad en este sentido.</li>
                <li class="ng-binding">No obstante,  cliptter.com  podrá llevar a cabo las verificaciones para constatar este hecho, adoptando las medidas de diligencia debida que correspondan, conforme a la normativa de protección de datos.</li>
              </ul>





            <h3>Legitimación – ¿cuál es la legitimación para el tratamiento de tus datos?</h3>

            <p class="ng-binding">El tratamiento de datos cuyo fin es el envío de boletines periódicos (newslettering) sobre servicios, eventos y noticias relacionadas con nuestra actividad profesional se basa en el consentimiento del interesado, solicitado expresamente para llevar a cabo dichos tratamientos, de acuerdo con la normativa vigente.<br>
            Además, la legitimación para el tratamiento de los datos relacionados con ofertas  o colaboraciones se basan en el consentimiento del usuario que remite sus datos, que puede retirar en cualquier momento, si bien ello puede afectar a la posible comunicación de forma fluida y obstrucción de procesos que desea realizar.<br>
            Por último, los datos se podrán utilizar para dar cumplimiento a las obligaciones legales aplicables a cliptter.com
            </p>





            <h3>Plazo de Conservación de los Datos – ¿Por cuánto tiempo conservaremos tus datos?</h3>

            <p class="ng-binding">cliptter.com  conservará los datos personales de los usuarios únicamente durante el tiempo necesario para la realización de las finalidades para las que fueron recogidos, mientras no revoque los consentimientos otorgados. Posteriormente, en caso de ser necesario, mantendrá la información bloqueada durante los plazos legalmente establecidos.</p>

              <h3>Destinatarios ¿A qué destinatarios se comunicarán tus datos?</h3>

            <p class="ng-binding">Tus datos podrán ser accedidos por aquellos proveedores que prestan servicios a cliptter.com, tales como servicios de alojamiento, herramientas de marketing  y  sistemas  de contenido u otros profesionales, cuando dicha comunicación sea necesaria normativamente, o para la ejecución de los servicios contratados.<br><br>
            cliptter.com, ha suscrito los correspondientes contratos de encargo de tratamiento con cada uno de los proveedores que prestan servicios a cliptter.com, con el objetivo de garantizar que dichos proveedores tratarán tus datos de conformidad con lo establecido en la legislación vigente.<br><br>
            También podrán ser cedidos a las Fuerzas y Cuerpos de Seguridad del Estado en los casos que exista una obligación legal.<br><br>
            Bancos y entidades financieras, para el cobro de los servicios.<br>
            Administraciones públicas con competencia en los sectores de actividad, cuando así lo establezca la normativa vigente.
            </p>

            <h3>
              Seguridad de la Información – ¿Qué medidas de seguridad implantamos para cuidar sus datos?
            </h3>
            <p>
              Para proteger las diferentes tipologías de datos reflejados en esta política de privacidad llevará a cabo las medidas de seguridad técnicas necesarias para evitar su pérdida, manipulación, difusión o alteración.
            </p>
            <ul>
              <li class="ng-binding">Encriptación de las comunicaciones entre el dispositivo del usuario y los servidores de cliptter.com </li>
              <li class="ng-binding">Encriptación de la información en los propios servidores de cliptter.com</li>
              <li>Otras medidas que eviten el acceso a los datos del usuario por parte de terceros.</li>
              <li class="ng-binding">En aquellos casos en los que cliptter.com  cuente con prestadores de servicio para el mantenimiento de la plataforma que se encuentren fuera de la Unión Europea, estas trasferencias internacionales se hayan regularizadas atendiendo al compromiso de cliptter.com  con la protección, integridad y seguridad de los datos personales de los usuarios.</li>
            </ul>

            <h3>Derechos – ¿Cuáles son tus derechos cuando nos facilitas tus datos y cómo puedes ejercerlos?</h3>
            <p class="ng-binding">
             Tienes derecho a obtener confirmación sobre si en cliptter.com estamos tratando datos personales que te conciernan, o no.<br>
            Asimismo, tienes derecho a acceder a tus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.<br><br>
            En determinadas circunstancias, podrás solicitar la limitación del tratamiento de tus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.<br>
            En determinadas circunstancias y por motivos relacionados con tu situación particular, podrás oponerte al tratamiento de tus datos. cliptter.com  dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.<br><br>
            Asimismo, puedes ejercer el derecho a la portabilidad de los datos, así como retirar los consentimientos facilitados en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.<br><br>

            Si deseas hacer uso de cualquiera de tus derechos puede dirigirse a <a href="mailto:cliptter@gmail.com" class="ng-binding">cliptter@gmail.com</a>. <br><br>
            Por último, te informamos que puedes dirigirte ante la Agencia Española de Protección de Datos y demás organismos públicos competentes para cualquier reclamación derivada del tratamiento de tus datos personales.

            </p>


            <h3>Modificación de la política de privacidad </h3>


            <p class="ng-binding">
               cliptter.com podrá modificar la presente Política de Privacidad en cualquier momento, siendo publicadas las sucesivas versiones en el Sitio Web. En cualquier caso, cliptter.com comunicará con previo aviso las modificaciones de la presente política que afecten a los usuarios a fin de que puedan aceptar las mismas. <br><br>
            La presente Política de Privacidad se encuentra actualizada a fecha 27/12/2018 cliptter.com  (España). Reservados todos los derechos.
             <br><br>
            Si lo deseas también puedes consultar nuestra  <strong><a href="https://cliptter.com/cookies">Política de Cookies</a></strong>

            </p>



            </div>
          </div>
        </article>

        <div class="row">

    <div class="col s12">

      <article id="content">
          <header style="padding-left:0">
            <h2>Aviso legal y términos de uso</h2>
          </header>
          <div class="">
            <div class="">



            <p class="ng-binding">
              En este espacio, el USUARIO, podrá encontrar toda la información relativa a los términos y condiciones legales que definen las relaciones entre los usuarios y nosotros como responsables de esta web. Como usuario, es importante que conozcas estos términos antes de continuar tu navegación.
            cliptter.com.Como responsable de esta web, asume el compromiso de procesar la información de nuestros usuarios y clientes con plenas garantías y cumplir con los requisitos nacionales y europeos que regulan la recopilación y uso de los datos personales de nuestros usuarios.
            Esta web, por tanto, cumple rigurosamente con el RGPD (REGLAMENTO (UE) 2016/679 de protección de datos) y  la LSSI-CE la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico.

            </p>

            <h3>CONDICIONES GENERALES DE USO</h3>

            <p class="ng-binding">Las presentes Condiciones Generales regulan el uso (incluyendo el mero acceso) de las páginas de la web, integrantes del sitio web de  cliptter.com incluidos los contenidos y servicios puestos a disposición en ellas. Toda persona que acceda a la web, cliptter.com (“Usuario”) acepta someterse a las Condiciones Generales vigentes en cada momento del portal cliptter.com.</p>


            <h3>DATOS PERSONALES QUE RECABAMOS Y CÓMO LO HACEMOS</h3>

            Leer <a href="">Política de Privacidad</a>

            <h3>COMPROMISOS Y OBLIGACIONES DE LOS USUARIOS</h3>


            <p class="ng-binding">
              El Usuario queda informado, y acepta, que el acceso a la presente web no supone, en modo alguno, el inicio de una relación comercial con cliptter.com. De esta forma, el usuario se compromete a utilizar el sitio Web, sus servicios y contenidos sin contravenir la legislación vigente, la buena fe y el orden público.<br>
            Queda prohibido el uso de la web, con fines ilícitos o lesivos, o que, de cualquier forma, puedan causar perjuicio o impedir el normal funcionamiento del sitio web. Respecto de los contenidos de esta web, se prohíbe:Su reproducción, distribución o modificación, total o parcial, a menos que se cuente con la autorización de sus legítimos titulares;Cualquier vulneración de los derechos del prestador o de los legítimos titulares;Su utilización para fines comerciales o publicitarios.<br>

            <br>
            En la utilización de la web, cliptter.com, el Usuario se compromete a no llevar a cabo ninguna conducta que pudiera dañar la imagen, los intereses y los derechos de cliptter.com o de terceros o que pudiera dañar, inutilizar o sobrecargar el portal (indicar dominio)  o que impidiera, de cualquier forma, la normal utilización de la web.
            No obstante, el Usuario debe ser consciente de que las medidas de seguridad de los sistemas informáticos en Internet no son enteramente fiables y que, por tanto cliptter.com  no puede garantizar la inexistencia de virus u otros elementos que puedan producir alteraciones en los sistemas informáticos (software y hardware) del Usuario o en sus documentos electrónicos y ficheros contenidos en los mismos.

            </p>

            <h3>MEDIDAS DE SEGURIDAD</h3>
            <p class="ng-binding">
              Los datos personales comunicados por el usuario a cliptter.com pueden ser almacenados en bases de datos automatizadas o no, cuya titularidad corresponde en exclusiva a cliptter.com, asumiendo ésta todas las medidas de índole técnica, organizativa y de seguridad que garantizan la confidencialidad, integridad y calidad de la información contenida en las mismas de acuerdo con lo establecido en la normativa vigente en protección de datos.<br>
            La comunicación entre los usuarios y cliptter.com utiliza un canal seguro, y los  datos transmitidos son cifrados gracias a protocolos a https, por tanto, garantizamos las mejores condiciones de seguridad para que la confidencialidad de los usuarios esté garantizada.

            </p>

            <h3>RECLAMACIONES</h3>
            <p class="ng-binding">cliptter.com informa que existen hojas de reclamación a disposición de usuarios y clientes.
            El Usuario podrá realizar reclamaciones solicitando su hoja de reclamación o remitiendo un correo electrónico a <a href="mailto:cliptter@gmail.com" class="ng-binding">cliptter@gmail.com</a> indicando su nombre y apellidos, el servicio y/o producto adquirido y exponiendo los motivos de su reclamación.<br><br>
            El usuario/comprador podrá notificarnos la reclamación, bien a través de correo electrónico a: <a href="mailto:cliptter@gmail.com" class="ng-binding">cliptter@gmail.com</a>, si lo desea adjuntando el siguiente formulario de reclamación:
            El servicio/producto:
            Adquirido el día:
            Nombre del usuario:
            Domicilio del usuario:
            Firma del usuario (solo si se presenta en papel):
            Fecha:
            Motivo de la reclamación:
            </p>

            <h3>PLATAFORMA DE RESOLUCIÓN DE CONFLICTOS</h3>




            <p>Por si puede ser de tu interés, para someter tus reclamaciones puedes utilizar también la plataforma de resolución de litigios que facilita la Comisión Europea y que se encuentra disponible en el siguiente enlace: <a href="http://ec.europa.eu/consumers/odr/" rel="no-follow">http://ec.europa.eu/consumers/odr/</a></p>





            <h3>DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h3>

            <p class="ng-binding">En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de cliptter.com. El usuario se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de cliptter.com.<br>
            El usuario conoce y acepta que la totalidad del sitio web, conteniendo sin carácter exhaustivo el texto, software, contenidos (incluyendo estructura, selección, ordenación y presentación de los mismos) podcast, fotografías, material audiovisual y gráficos, está protegida por marcas, derechos de autor y otros derechos legítimos, de acuerdo con los tratados internacionales en los que España es parte y otros derechos de propiedad y leyes de España.
            En el caso de que un usuario o un tercero consideren que se ha producido una violación de sus legítimos derechos de propiedad intelectual por la introducción de un determinado contenido en la web,  deberá notificar dicha circunstancia a  cliptter.com  indicando:<br>

            </p><ul>
              <li>
                Datos personales del interesado titular de los derechos presuntamente infringidos, o indicar la representación con la que actúa en caso de que la reclamación la presente un tercero distinto del interesado.
              </li>

              <li>
                Señalar los contenidos protegidos por los derechos de propiedad intelectual y su ubicación en la web, la acreditación de los derechos de propiedad intelectual señalados y declaración expresa en la que el interesado se responsabiliza de la veracidad de las informaciones facilitadas en la notificación
              </li>
            </ul>

            <p></p>

            <h3>ENLACES EXTERNOS</h3>

            <p class="ng-binding">Las páginas de la web cliptter.com, podría proporcionar enlaces a otros sitios web propios y contenidos que son propiedad de terceros.
            El único objeto de los enlaces es proporcionar al Usuario la posibilidad de acceder a dichos enlaces.
            cliptter.com  no se responsabiliza en ningún caso de los resultados que puedan derivarse al Usuario por acceso a dichos enlaces.<br>
            Asimismo, el usuario encontrará dentro de este sitio, páginas, promociones, programas de afiliados que acceden a los hábitos de navegación de los usuarios para establecer perfiles. Esta información siempre es anónima y no se identifica al usuario.<br><br>
            La Información que se proporcione en estos Sitios patrocinado o enlaces de afiliados está sujeta a las políticas de privacidad que se utilicen en dichos Sitios y no estará sujeta a esta política de privacidad. Por lo que recomendamos ampliamente a los Usuarios a revisar detalladamente las políticas de privacidad de los enlaces de afiliado.<br>
            El Usuario que se proponga establecer cualquier dispositivo técnico de enlace desde su sitio web al portal cliptter.com deberá obtener la autorización previa y escrita de cliptter.com El establecimiento del enlace no implica en ningún caso la existencia de relaciones entre cliptter.com y el propietario del sitio en el que se establezca el enlace, ni la aceptación o aprobación por parte de  cliptter.com de sus contenidos o servicios
            </p>

            <h3>POLÍTICA DE COMENTARIOS</h3>
            <p>En nuestra web y se permiten realizar comentarios para enriquecer los contenidos y realizar consultas.
            No se admitirán comentarios que no estén relacionados con la temática de esta web, que incluyan difamaciones, agravios, insultos, ataques personales o faltas de respeto en general hacia el autor o hacia otros miembros.
            También serán suprimidos los comentarios que contengan información que sea obviamente engañosa o falsa, así como los comentarios que contengan información personal, como, por ejemplo, domicilios privado o teléfonos y que vulneren nuestra política de protección de datos.<br>
            Se desestimará, igualmente, aquellos comentarios creados sólo con fines promocionales de una web, persona o colectivo y todo lo que pueda ser considerado spam en general.<br>
            No se permiten comentarios anónimos, así como aquellos realizados por una misma persona con distintos apodos. No se considerarán tampoco aquellos comentarios que intenten forzar un debate o una toma de postura por otro usuario.
            </p>


            <h3>EXCLUSIÓN DE GARANTÍAS Y  RESPONSABILIDAD</h3>




            <p>El Prestador no otorga ninguna garantía ni se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran traer causa de:


            </p><ul>
              <li>
                La falta de disponibilidad, mantenimiento y efectivo funcionamiento de la web, o de sus servicios y contenidos;
              </li>
                <li>
                 La existencia de virus, programas maliciosos o lesivos en los contenidos;
              </li>
                <li>
                El uso ilícito, negligente, fraudulento o contrario a este Aviso Legal;
              </li>
                <li>
                La falta de licitud, calidad, fiabilidad, utilidad y disponibilidad de los servicios prestados por terceros y puestos a disposición de los usuarios en el sitio web.
              </li>
                <li>
                El prestador no se hace responsable bajo ningún concepto de los daños que pudieran dimanar del uso ilegal o indebido de la presente página web.
              </li>
            </ul>
            <p></p>




            <h3>LEY APLICABLE Y JURISDICCIÓN</h3>
            <p class="ng-binding">Con carácter general las relaciones entre cliptter.com con los Usuarios de sus servicios telemáticos, presentes en esta web se encuentran sometidas a la legislación y jurisdicción españolas y a los tribunales.</p>


            <h3>CONTACTO</h3>
            <p class="ng-binding">En caso de que cualquier Usuario tuviese alguna duda acerca de estas Condiciones legales o cualquier comentario sobre el portal cliptter.com, por favor diríjase a <a href="mailto:cliptter@gmail.com" class="ng-binding">cliptter@gmail.com</a></p>
            <p class="ng-binding">
            De parte del equipo que formamos cliptter.com te agradecemos el tiempo dedicado en leer este Aviso Legal
            </p>



                    </div>
                  </div>
                </article>
                </div>



              </div>
    </div>



  </div>
          </div>

          <!--Barra lateral derecha, solo PC-->
          <div class="d-none d-md-block col-md-3 col-lg-4" style="border-left: 1px solid #38444d; padding-top: 10px">
            <div style="position: sticky; top: 10px">
            <Tendencias/>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal para añadir-->
      <ModalAddVideo :tweet_detail="$root.tweet_detail"></ModalAddVideo>
  </div>
</template>

<script>
import PageMixin from './PageMixin'
export default {
    name: "PageTutorial",
    mixins: [PageMixin],
    data: () => {
        return {
          items: [],
          infiniteScroll : {
            load: true,
            page: 0,
            total_pages: 0
          }
        }
    }
  }
</script>
