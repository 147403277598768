<template>
  <div>
    <MenuMovil></MenuMovil>
      <!--Contenido principal-->
      <div class="container" style="min-height: 100vh;">
        <div class="row">
          <!-- Menú lateral solo PC-->
          <MenuPc></MenuPc>
          <!-- Barra central-->
          <div class="col-12 col-md-8 col-lg-6" style="min-height: 100vh;">
            <div class="fijar-barra">
              <NavTop></NavTop>
            </div>

            <div class="row" style="padding: 4px">
              TUTORIAL
            </div>

          </div>

          <!--Barra lateral derecha, solo PC-->
          <div class="d-none d-md-block col-md-3 col-lg-4" style="border-left: 1px solid #38444d; padding-top: 10px">
            <div style="position: sticky; top: 10px">
            <Tendencias/>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal para añadir-->
      <ModalAddVideo :tweet_detail="$root.tweet_detail"></ModalAddVideo>
  </div>
</template>

<script>
import PageMixin from './PageMixin'
export default {
    name: "PageTutorial",
    mixins: [PageMixin],
    data: () => {
        return {
          items: [],
          infiniteScroll : {
            load: true,
            page: 0,
            total_pages: 0
          }
        }
    }
  }
</script>
