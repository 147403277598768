<template>
<div v-if="!licencia" id="div_loader" style="height:100%;display: flex;
align-items: center;
justify-content: center; flex-direction:column; text-align:center;">
    <div class="logo_container" >
      <div style="margin:0 auto; margin-top:50px; margin-bottom:50px;">CARGANDO CONTENIDO</div>
      <half-circle-spinner
          :animation-duration="1000"
          :size="60"
          color="#00a478"
           style="margin:0 auto;" />
           <div style="margin:0 auto; margin-top:50px;">{{ countDown }}</div>

           <a v-if="0 == 1" href="#" class="link" style="margin:0 auto; margin-top:50px;" @click="goto_suscribete()">Cargando contenido</a>
    </div>

  </div>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners'

export default {
    name: "VideoPublicidad",
    props: ['licencia'],
    components: {
      HalfCircleSpinner
    },
    data: () => {
        return {
          countDown : 3,
          contador : null
        }
    },
    methods: {
      countDownTimer() {
        var self = this;
          if(this.countDown > 0) {
              this.contador = setTimeout(() => {
                  self.countDown -= 1
                  self.countDownTimer()
              }, 1000)
          }
          else if(this.countDown <= 0){
            setTimeout(() => {
                self.$parent.cerrarCargador()
            }, 500)
          }
      },
      cargarPublicidad() {
        this.countDownTimer();
      },
      goto_suscribete() {
        this.$parent.closeModalAndGoSuscribete()
      }
    },
    mounted: function () {
      if(!this.licencia)
        this.cargarPublicidad()
      else {
        this.$parent.cerrarCargador()
      }
    },
    beforeDestroy: function () {
      clearTimeout(this.contador);
    }
}
</script>
