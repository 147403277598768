<template>
  <div>
    <MenuMovil></MenuMovil>
      <!--Contenido principal-->
      <div class="container" style="min-height: 100vh;">
        <div class="row">
          <!-- Menú lateral solo PC-->
          <MenuPc></MenuPc>
          <!-- Barra central-->
          <div class="col-12 col-md-8 col-lg-6" style="min-height: 100vh; padding-bottom: 40px">
          <div class="fijar-barra">

            <NavTop></NavTop>
            <div class="row" style="background-color: #15202b;">
              <div class="col-12" style="margin-top: 15px">

                <div class="form-group">
                  <input type="text" class="form-control" id="buscar" aria-describedby="buscar" placeholder="Buscar..." v-model="$root.filter_seccion.buscar">
                </div>
              </div>
              <div class="col-6" style="padding-left: 0; padding-right: 0" @click="$root.filter_seccion.seccion = 'temas'">
                <div class="filter_seccion" :class="{'selected': $root.filter_seccion.seccion == 'temas'}" style="background-color: #15202b;">Temas</div>
              </div>
              <div class="col-6" style="padding-left: 0; padding-right: 0" @click="$root.filter_seccion.seccion = 'autores'">
                <div class="filter_seccion" :class="{'selected': $root.filter_seccion.seccion == 'autores'}" style="background-color: #15202b;">Autores</div>
              </div>
            </div>
          </div>

            <div v-infinite-scroll="getData" infinite-scroll-disabled="busy" infinite-scroll-distance="100" id="parent-grid" class="row" style="padding: 4px">
              <!--DIV filtro autor-->
              <div v-for="item in items" :key="item.user_id" :data-index="item.user_id" v-if="$root.filter_seccion.seccion == 'autores'" class="col-4" style="padding: 20px" @click="filtrarPorUsuario(item.user, null, 'PageSecciones')">
                  <div class="div-cuadrado-video" :style="{ 'background-image': 'url(' + item.avatar + ')'}" style="border-radius: 50%;"></div>
                  <div style="width: 100%; text-align: center; font-weight: bold; font-size: 14px; margin-top: 5px; overflow:hidden; white-space:nowrap; text-overflow: ellipsis;">@{{item.user}}</div>
              </div>

              <!--BUCLE categorias -->
              <div v-for="item in items" :key="item.tema_url" :data-index="item.tema_url" v-if="$root.filter_seccion.seccion == 'temas'" class="col-6" style="padding: 20px" @click="filtrarPorTema(item.tema_url, null, 'PageSecciones')">
                <div class="div-cuadrado-video" :style="{ 'background-image': 'url(' + item.avatar + ')'}" style="border-radius: 25px;">
                </div>
                <div style="width: 100%; text-align: center; font-weight: bold; font-size: 14px; margin-top: 5px">{{item.tema}}</div>
              </div>
            </div>

            <div class="page-load-status">
              <div v-if="infiniteScroll.load" class="row">
                <div class="col-12 text-center">
                  <img src="@/assets/images/bars.svg" alt="" style="margin-top: 30px">
                </div>
              </div>
              <p v-else class="infinite-scroll-error"></p>
            </div>
            <div v-if="items.length == 0 && !infiniteScroll.load" class="row">
              <div class="col-12" style="text-align: center; font-weight: bold; padding: 20px">
                <img src="@/assets/images/noresultados.png" alt="" style="width: 150px" />
              </div>
              <div class="col-12" style="text-align: center; font-weight: bold">
              {{items.length}}  No se han encontrado resultados, se recomienda utilizar una sola palabra.
              </div>
            </div>
          </div>

          <!--Barra lateral derecha, solo PC-->
          <div class="d-none d-md-block col-md-3 col-lg-4" style="border-left: 1px solid #38444d; padding-top: 10px">
<div style="position: sticky; top: 10px">

            <Tendencias/>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal para añadir-->
      <ModalAddVideo :tweet_detail="$root.tweet_detail"></ModalAddVideo>
  </div>
</template>

<script>
import PageMixin from './PageMixin'
import axios from "axios"

export default {
    name: "PageSecciones",
    mixins: [PageMixin],
    data: () => {
        return {
          items: [],
          infiniteScroll : {
            load: true,
            page: 0,
            total_pages: 0
          }
        }
    },
    components: {
    },
    methods: {
      addItems: function (items) {
        if(this.infiniteScroll.page==1)
          this.items = items
        else {
          if(Object.keys(this.items).length > 0)
          {
            for (var i in items) {
              this.items.push(items[i])
            }
          }
          else {
            this.items.push(items)
          }
        }
      },
      getData: function() {
        let self = this
        this.infiniteScroll.load = true
        this.infiniteScroll.page = this.infiniteScroll.page + 1
        if (this.infiniteScroll.page <= this.infiniteScroll.total_pages || this.infiniteScroll.page == 1) {
            axios.get(process.env.VUE_APP_API_BASE_URL + '/seccion', { params: {
              page: self.infiniteScroll.page,
              seccion: self.$root.filter_seccion.seccion,
              buscar: self.$root.filter_seccion.buscar
            }
          }).then(response => {
            this.addItems(response.data.items)
            this.infiniteScroll.total_pages = response.data.total_pages
            this.infiniteScroll.load = false
          })
          .catch(err => {
            console.log(err)
            this.infiniteScroll.load = false
          })
        }
        else {
            this.infiniteScroll.load = false
        }
      }
    },
    mounted: function () {
      this.infiniteScroll.page = 0
    },
    beforeMounted: function () {
    },
    watch: {
      '$root.filter_seccion': {
        handler: function(val, oldVal) {
          // console.log('value changed from ' + oldVal + ' to ' + val);
          window.scrollTo(0, 0);
          this.infiniteScroll.page = 0
          this.items = []
          this.getData()
        },
        deep: true
      }
    }
  }
</script>
