import axios from "axios"

import MenuPc from "./MenuPc.vue"
import MenuMovil from "./MenuMovil.vue"
import Tendencias from "./Tendencias.vue"
import ModalVerVideo from "./ModalVerVideo.vue"
import ModalAddVideo from "./ModalAddVideo.vue"
import ModalEditVideo from "./ModalEditVideo.vue"
import Loader from './Loader.vue'
import NavTop from "./NavTop.vue"
import NuevasCondiciones from "./NuevasCondiciones.vue"

export default {
  components: {
    MenuPc,
    MenuMovil,
    Tendencias,
    ModalVerVideo,
    ModalAddVideo,
    ModalEditVideo,
    NavTop,
    Loader,
    NuevasCondiciones
  },
  data: () => {
    return {

    }
  },
  methods: {
    setHeader: function(token) {
      axios.defaults.headers.common = {
          'Authorization': 'Bearer ' + token
      }
    },
    login: function () {
      window.location.href = process.env.VUE_APP_API_BASE_URL + '/login' + '?url_callback=' + process.env.VUE_APP_TWEETER_CALLBACK_URL + '&url_return=' + document.URL
    },
    logout() {
      let self = this
      axios.get(process.env.VUE_APP_API_BASE_URL + '/logout').then(response => {
        if (response.status === 200) {
          this.$store.dispatch("setToken", null)
          axios.defaults.headers.common = {}
          $.alert({
            title: 'Logout',
            theme: 'dark',
            content: 'Se ha cerrado la sesión correctamente',
            type: 'black',
            buttons: {
              ok: {
                  text: "Aceptar",
                  btnClass: 'btn-primary',
                  keys: ['enter'],
                  action: function(){
                    window.location.reload();
                  }
              }
             }
          });
        }
      })
      .catch(err => {
          console.log("Error");
          console.log(err);
          self.$root.showAlert_error(err)
      });

    },
    isLogued() {
      if (this.$store.state.session.token) {
        return true;
      }
      return false;
    },
    setPage(page, param) {
      if(page=='PageFavoritos'){
        if(!this.isLogued()) {
          this.$root.showAlert_logueate()
          return
        }
      }

      $("#app").css({opactity:0});

      if(page=='PageFavoritos'){
        this.filtrarPorUsuario(null, null, null)
        this.$root.filter.favoritos = true
      }
      else if (page=='PageDescubre' || page=='PageAutor' || page=='PageTema') {
        this.$root.filter.favoritos = false
      }

      let self = this
      setTimeout(function() {

        switch (page) {
          case "PageInicio":
            self.$router.push('/').catch(()=>{});
            break;
          case "PageDescubre":
            self.$root.filter.tema.tema_url= null
            self.$root.filter.autor.user= null
            if(param) {
              self.$router.push('/descubre/'+ param).catch(()=>{});
              self.$root.filter.buscar = param
            }
            else {
              self.$router.push('/descubre').catch(()=>{});
              self.$root.filter.buscar = null
            }
            break;
          case "PageFavoritos":
            self.$router.push('/favoritos').catch(()=>{});
            break;
          case "PageAutor":
            self.$router.push('/autor/'+param).catch(()=>{});
            break;
          case "PageTema":
            self.$router.push('/tema/'+param).catch(()=>{});
            break;
          case "PageVideo":
            self.$router.push('/video/'+param).catch(()=>{});
            break;
          case "PageSecciones":
            self.$router.push('/secciones').catch(()=>{});
            break;
          case "PageTutorial":
            self.$router.push('/tutorial').catch(()=>{});
            break;
          case "PageCliptter":
            self.$router.push('/cliptter').catch(()=>{});
            break;
          case "PageCookies":
            self.$router.push('/cookies').catch(()=>{});
            break;
          case "PagePrivacidad":
            self.$router.push('/privacidad').catch(()=>{});
            break;
          case "PageSuscribete":
            self.$router.push('/suscribete').catch(()=>{});
            break;
        }
        $("#app").css({opactity:1});
      }, 10);

    },
    addVideoUpload(file_name, type) {
      let self = this
      self.$root.show_loader = true
      if (type == "video") {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/addVideoUpload', {
          file_name: file_name,
          input_protagonista: self.tweet_detail.input_protagonista,
          input_descripcion: self.tweet_detail.input_descripcion,
          input_hashtags: self.tweet_detail.input_hashtags,
          input_temas: self.tweet_detail.input_temas
        });
      }
      else {
        return axios.post(process.env.VUE_APP_API_BASE_URL + '/addVideoUpload', {
          file_name: file_name,
          id_tweet: self.tweet_detail.id_tweet,
          url: self.tweet_detail.url,
          autor: self.tweet_detail.autor,
          input_enlace: self.tweet_detail.input_enlace,
          input_protagonista: self.tweet_detail.input_protagonista,
          input_descripcion: self.tweet_detail.input_descripcion,
          input_hashtags: self.tweet_detail.input_hashtags,
          input_temas: self.tweet_detail.input_temas
        });
      }
    },
    copyToClipboard (url) {
      url = url + " #CliptterVideos"
      try {
        $("#snackbar").show();

        setTimeout(function() {
          $("#snackbar").fadeOut();
        }, 500);


        const el = document.createElement('textarea');  // Create a <textarea> element
          el.value = url;                                 // Set its value to the string that you want copied
          el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
          el.style.position = 'absolute';
          el.style.left = '-9999px';                      // Move outside the screen to make it invisible
          document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
          const selected =
            document.getSelection().rangeCount > 0        // Check if there is any content selected previously
              ? document.getSelection().getRangeAt(0)     // Store selection if found
              : false;                                    // Mark as false to know no selection existed before
          el.select();                                    // Select the <textarea> content
          document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
          document.body.removeChild(el);                  // Remove the <textarea> element
          if (selected) {                                 // If a selection existed before copying
            document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
            document.getSelection().addRange(selected);   // Restore the original selection
          }



            navigator.clipboard.writeText(url);

        // 2) Catch errors
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },
    filtrarPorUsuario(user, buscar, from) {
      this.$root.filter.buscar = buscar
      this.$root.filter.tema.tema_url=null
      this.$root.filter.autor.user=user
      if(user) {
        this.$root.filter.autor.from=from
        this.getInfoUser(user)
        if(!buscar) {
          this.setPage("PageAutor", user)
        }
        else {
          this.setPage("PageAutor", user + "/" + buscar)
        }
      }
      else if(this.$root.filter.autor.from) {
          this.setPage(this.$root.filter.autor.from)
      }
      else {
        this.setPage('PageDescubre')
      }

    },
    filtrarPorTema(tema_url, buscar, from) {
      this.$root.filter.buscar = buscar
      this.$root.filter.autor.user=null
      this.$root.filter.tema.tema_url=tema_url
      if(tema_url) {
        this.$root.filter.tema.from=from
        this.getInfoTema(tema_url)
        if(!buscar)
          this.setPage("PageTema", tema_url)
        else
          this.setPage("PageTema", tema_url + "/" + buscar)
      }
      else if(this.$root.filter.tema.from) {
          this.setPage(this.$root.filter.tema.from)
      }
      else {
        this.setPage('PageDescubre')
      }
    },
    getTemas() {
      let self = this
      axios.get(process.env.VUE_APP_API_BASE_URL + '/getTemas').then(response => {
        if (response.status === 200) {
          self.temas=response.data
        }
      })
      .catch(err => {
          console.log("Error");
          console.log(err);
          self.$root.showAlert_error(err)
      });
    },
    getInfoUser: function (user) {
      let self = this
      axios.get(process.env.VUE_APP_API_BASE_URL + '/getInfoUser', { params: {
        user: user
      }
    }).then(response => {
        if (response.status === 200) {
          if(response.data.error)
            console.log(response.data.message)
          else
          {
            self.$root.filter.autor.name = response.data.name
            self.$root.filter.autor.avatar = response.data.avatar
            self.$root.filter.autor.banner = response.data.banner
            self.$root.filter.autor.videos = response.data.videos
            self.$root.filter.autor.favoritos = response.data.favoritos
          }
        }
      })
      .catch(err => {
          console.log("Error");
          console.log(err);
          self.$root.showAlert_error(err)
      });
    },
    getInfoTema: function (tema_url) {
      let self = this
      axios.get(process.env.VUE_APP_API_BASE_URL + '/getInfoTema', { params: {
        tema_url: tema_url
      }
    }).then(response => {
        if (response.status === 200) {
          if(response.data.error)
            console.log(response.data.message)
          else
          {
            self.$root.filter.tema.tema = response.data.tema
            self.$root.filter.tema.banner = response.data.banner
            self.$root.filter.tema.avatar = response.data.avatar
            self.$root.filter.tema.videos = response.data.videos
            self.$root.filter.tema.favoritos = response.data.favoritos
          }
        }
      })
      .catch(err => {
          console.log("Error");
          console.log(err);
          self.$root.showAlert_error(err)
      });
    },
    getTendencias() {
      let self = this
      axios.get(process.env.VUE_APP_API_BASE_URL + '/getTendencias').then(response => {
        if (response.status === 200) {
          self.tendencias=response.data
        }
      })
      .catch(err => {
          console.log("Error");
          console.log(err);
          self.$root.showAlert_error(err)
      });
    },
    showAlert_logueate() {
      let self = this
      $.confirm({
        title: 'Login',
        theme: 'dark',
        content: 'Es necesario que hagas Login con tu cuenta de twitter',
        type: 'black',
        buttons: {
          ok: {
              text: "Autorizar",
              btnClass: 'btn-primary',
              keys: ['enter'],
              action: function(){
                   self.$root.login()
              }
          },
          cancel: {
            text: "Cancelar",
            action: function(){

             }
           }
         }
      });
    },
    showAlert_logueate() {
      let self = this
      $.confirm({
        title: 'Login',
        theme: 'dark',
        content: 'Es necesario que hagas Login con tu cuenta de twitter',
        type: 'black',
        buttons: {
          ok: {
              text: "Autorizar",
              btnClass: 'btn-primary',
              keys: ['enter'],
              action: function(){
                   self.$root.login()
              }
          },
          cancel: {
            text: "Cancelar",
            action: function(){

             }
           }
         }
      });
    },
    showAlert_error(msg) {
      let self = this
      $.alert({
        title: 'Error',
        theme: 'dark',
        content: msg,
        type: 'red',
        buttons: {
          ok: {
              text: "Aceptar",
              btnClass: 'btn-primary',
              keys: ['enter'],
              action: function(){

              }
          }
         }
      });
    },
    showAlert_confirm(msg) {
      let self = this
      $.alert({
        title: 'Confirmación',
        theme: 'dark',
        content: msg,
        type: 'blue',
        buttons: {
          ok: {
              text: "Aceptar",
              btnClass: 'btn-primary',
              keys: ['enter'],
              action: function(){
              }
          }
         }
      });
    },
    handleResize() {
     if(window.innerWidth < 768)
      this.$root.currentMenu = "movil"
     else
      this.$root.currentMenu = "pc"
   },
   showNuevasCondiciones() {
     $('#vueModalNuevasCondiciones').modal({
         show: true,
         keyboard: false,
         backdrop: 'static'
       });
   },
   loadAdMobile() {

     if(document.getElementById("adScript"))
      document.getElementById("adScript").remove();
     let adScript = document.createElement('script')
     adScript.setAttribute("id", "adScript");
     adScript.setAttribute('src', 'https://a.exdynsrv.com/js.php?t=17&idzone=4529604')
     document.head.appendChild(adScript)
   },
   showModalDonar() {
     $.confirm({
       title: '<div style="text-align:center">Donación</div>',
       theme: 'dark',
       content: `<div style="text-align:center;">Recuerda marcar la opción de <b>enviar a un amigo</b>. ¡GRACIAS!<BR><BR>
         <a href="/static/images/donacion/ejemplo_donacion_step3B.jpg" target="_blank"><img src="/static/images/donacion/ejemplo_donacion_step3B.jpg" style="width:100%; max-width:600px;">
       </div>`,
       type: 'black',
       columnClass: 'col-md-12',
       buttons: {
         ok: {
             text: "Continuar",
             btnClass: 'btn-primary',
             keys: ['enter'],
             action: function(){
                  window.location.href = 'https://paypal.me/cliptter'
             }
         },
         cancel: {
           text: "Cancelar",
           action: function(){

            }
          }
        }
     });
   }
  },
  created () {
    var self = this
    window.addEventListener("resize", this.handleResize);
    this.handleResize()
    if(this.$route.query.token)
    {
      self.$root.show_loader = true
      axios.get(process.env.VUE_APP_API_BASE_URL + '/validateToken', { params:{
        token: this.$route.query.token
      }}).then(response => {
        if (response.status === 200) {
          if(response.data.result == 1)
          {
            this.$store.dispatch("setToken", response.data.token)
            this.$store.dispatch("setId_user", response.data.id_user)
            this.$store.dispatch("setPaypalToken", response.data.paypal_token)
            this.$store.dispatch("setUser", response.data.user)
            this.$store.dispatch("setName", response.data.name)
            this.$store.dispatch("setAvatar", response.data.avatar)
            this.$store.dispatch("setBanner", response.data.banner)
            this.$store.dispatch("setAdmin", response.data.admin)
            this.$store.dispatch("setVideos", response.data.videos)
            this.$store.dispatch("setFavoritos", response.data.favoritos)
            this.$store.dispatch("setVideos_publicidad", 0)
          }
          this.$router.push(this.$route.path).catch(()=>{});
          window.location.reload();
        }
      })
      .catch(err => {
        self.$root.show_loader = false
        this.$router.push(this.$route.path).catch(()=>{});
        console.log("Error");
        console.log(err);
        self.$root.showAlert_error(err)
      })
      .finally(() => {
        self.$root.show_loader = false
      });
    }

    if (this.$store.state.session.token) {
      this.setHeader(this.$store.state.session.token)
    }


  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    'currentMenu': function (newVal, oldVal){
      this.$nextTick(() => {
        if(newVal == 'movil')
        {
          // this.loadAdMobile()
        }
      });
    }
  }

}
