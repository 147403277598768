<template>
  <div id="vueModalEditVideo" class="modal fade" role="dialog" ref="vueModalEditVideo">
    <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content" style="background-color: #15202b">
        <div class="modal-header" style="color: white; border-bottom-color: #3a5061">
          <h4 class="modal-title">Editar vídeo</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" style="color: white">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <video ref="plyr_edit" crossorigin id="player_edit" controls playsinline></video>
          <div class="formClip">
            <div class="form-group">
              <label for="inputProtagonista">Protagonista:</label>
              <input type="text" class="form-control inputEstilo" id="inputProtagonista" name="inputProtagonista"
                placeholder="¿Quién aparece en el vídeo?" v-model="tweet_detail.input_protagonista">
            </div>
            <div class="form-group">
              <label for="inputDescripcion">Descripción:</label>
              <textarea class="form-control inputEstilo" rows="5" id="inputDescripcion" name="inputDescripcion"
                placeholder="Escribe lo que se dice o sucede en el vídeo" style="border-radius: 10px" v-model="tweet_detail.input_descripcion"></textarea>
            </div>
            <div class="form-group">
              <label for="inputTemas">Temas:</label>
              <select ref="inputTemas" class="form-control" multiple="multiple" id="inputTemas" name="inputTemas" v-model="tweet_detail.input_temas" v-select2>
                <option v-for="tema in $root.temas" :key="tema.id_tema" :data-index="tema.id_tema" :value="tema.id_tema">{{tema.tema}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="inputHashtags">Hashtags:</label>
              <input type="text" class="form-control inputEstilo" id="inputHashtags" name="inputHashtags"
                placeholder="Añade etiquetas para que sea más fácil encontrarlo" v-model="tweet_detail.input_hashtags">
            </div>
            <div class="alert alert-warning" role="alert" id="tarjetaInfo" style="margin-top: 10px" v-if="tweet_detail.error.code == 1">
                <div id="aviso">{{tweet_detail.error.message}}</div>
            </div>
            <button type="submit" class="btn btn-danger" style="float: left; border-radius: 50px; font-weight: bold" @click="$parent.deleteVideo()">Eliminar</button>
            <button type="submit" class="btn btn-primary" style="background-color: #00a478; border-color: #00a478; float: right; border-radius: 50px; font-weight: bold" @click="$parent.editVideo()">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import VuePlyr from 'vue-plyr'
// import 'vue-plyr/dist/vue-plyr.css'
import Plyr from 'plyr';
import 'plyr/dist/plyr.css'

export default {
    name: "ModalEditVideo",
    props: ["tweet_detail"],
    components: {

    },
    data: () => {
        return {

        }
    },
    destroyed() {
      this.removePlayer();
    },
    methods: {
      removePlayer() {
        if (!this.player) {
          return;
        }
        this.player.pause;
        clearInterval(this.intervalId);
      },
      select2_temas() {
        $(this.$refs.inputTemas).val("")
        $(this.$refs.inputTemas).select2({
          multiple: true,
          tags: "true",
          maximumSelectionLength: 3,
          language: {
            maximumSelected: function (e) {
                var t = "Solo puedes seleccionar un máximo de " + e.maximum + " temas";
                return t;
            }
          }
        });
      },
      showModalEditVideo() {
        if(this.player)
          this.player.destroy();
        this.player = new Plyr('#player_edit');
        this.select2_temas();
        if (this.tweet_detail.videos_download.length) {
          this.player.source = { type: 'video', title: '', sources: [{ src: process.env.VUE_APP_API_BASE_URL + '/getVideo/' + this.tweet_detail.videos_download[this.tweet_detail.videos_download.length-1].file, type: 'video/mp4' }] };
          this.player.poster = this.getImageUrl(this.tweet_detail)
        }
      },
      closeModalEditVideo() {
        this.removePlayer();
      },
      getImageUrl(video) {
        return process.env.VUE_APP_API_BASE_URL + '/images/thumbnail/' + video.imagen
      }
    },
    mounted: function () {
      $(this.$refs.vueModalEditVideo).on("hidden.bs.modal", this.closeModalEditVideo)
      $(this.$refs.vueModalEditVideo).on("show.bs.modal", this.showModalEditVideo)
      this.$root.getTemas();
    },
    watch: {
      'tweet_detail.input_temas': {
        handler: function(val, oldVal) {
          $(this.$refs.inputTemas).val(this.tweet_detail.input_temas).trigger('change');
        },
        deep: true
      }
    }
}
</script>
