<template>
  <div id="app">
    <NuevasCondiciones></NuevasCondiciones>
    <Loader />
    <router-view />
  </div>
</template>

<script>
import Loader from './components/Loader.vue'
import NuevasCondiciones from './components/NuevasCondiciones.vue'
export default {
  // metaInfo: {
  //   meta: [{vmid: 'og:image', property: 'og:image', content: 'https://cliptter.com/static/images/avatarclipttertwitter.png' }]
  // },
  components: {Loader, NuevasCondiciones},
  data: () => {
    return {

    }
  }
}
</script>

<style>
</style>
