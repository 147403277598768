<template>
  <div>
    <MenuMovil></MenuMovil>
    <!--Contenido principal-->
    <div class="container" style="min-height: 100vh;">
      <div class="row">
        <!-- Menú lateral solo PC-->
        <MenuPc></MenuPc>
        <!-- Barra central-->
        <div class="col-12 col-md-8 col-lg-6" style="min-height: 100vh;">
          <div class="fijar-barra">
            <NavTop></NavTop>
          </div>
          <div class="row">
            <div class="col s12">
              <article id="content">
                <header style="padding-left:0px;">
                  <h2>¿Qué es clippter</h2>
                </header>
                <div class="">
                  <div class="">
                     <p>Bienvenid@ a cliptter. el objetivo de esta página web es ofrecer a los usuarios una biblioteca multimedia de vídeos cortos y poder encontrarlos de una manera fácil y rápida.</p>
                     <p>Gracias a la aportación de los usuarios, el contenido ofrecido es clasificado y descrito para que el resto de la comunidad de cliptter pueda encontrarlo eficazmente.</p>
                     <p>Ya somos miles de usuarios los que utilizamos esta página diariamente.</p>
                     <p>En la última actualización de la plataforma, se ha realizado una importante mejora. Ahora se pueden incluir videos propios y también hemos incorporado la posiblidad de compartirlos a través de la red social o app que desees.
                     <p>Hemos mejorado el sistema Open Graph para que al compartir ls url de cualquiera de los vídeos, estos aparezcan directamente en los post o mensajes que envíes.</p>
                    <!--Barra lateral derecha, solo PC-->
                  </div>
              </div>
              </article>
          </div>
      </div>
    </div>
    <div class="d-none d-md-block col-md-3 col-lg-4" style="border-left: 1px solid #38444d; padding-top: 10px">
      <div style="position: sticky; top: 10px">
      <Tendencias/>
      </div>
    </div>
  </div>
  </div>
  <!-- Modal para añadir-->
  <ModalAddVideo :tweet_detail="$root.tweet_detail"></ModalAddVideo>
</div>
</template>

<script>
import PageMixin from './PageMixin'
export default {
    name: "PageCliptter",
    mixins: [PageMixin],
    data: () => {
        return {
          items: [],
          infiniteScroll : {
            load: true,
            page: 0,
            total_pages: 0
          }
        }
    }
  }
</script>
