<template>
  <div>
    <div id="menuMovil">
      <div>
        <!--Barra fija inferior (solo móvil)-->
        <div v-if="$root.currentMenu == 'movil'" class="container-fluid barra" style="bottom: 0px; padding-top: 10px;">
          <div class="row">
            <div class="col-3">

              <div style="text-align: center" :class="{'selected': $route.name == 'PageInicio'}"  @click="$root.setPage('PageInicio')">
                <img :src="$route.name == 'PageInicio' ? icon_inicio_seleccionado : icon_inicio" style="width: 20px">
              </div>
            </div>
            <div class="col-3">
              <div style="text-align: center" :class="{'selected': ($route.name == 'PageDescubre' || $route.name == 'PageAutor' || $route.name == 'PageTema')}" @click="$root.setPage('PageDescubre')">
                <img :src="($route.name == 'PageDescubre' || $route.name == 'PageAutor' || $route.name == 'PageTema') ? icon_descubre_seleccionado : icon_descubre" style="width: 20px">
              </div>
            </div>
            <div class="col-3">
              <div style="text-align: center" :class="{'selected': $route.name == 'PageFavoritos'}" @click="$root.setPage('PageFavoritos')">
                <img :src="($route.name == 'PageFavoritos') ? icon_favoritos_seleccionado : icon_favoritos" style="width: 20px">
              </div>
            </div>
            <div class="col-3">
              <div style="text-align: center" :class="{'selected': $route.name == 'PageSecciones'}" @click="$root.setPage('PageSecciones')">
                <img :src="$route.name == 'PageSecciones' ? icon_secciones_seleccionado : icon_secciones" style="width: 20px">
              </div>
            </div>
          </div>
        </div>

        <!--Menú lateral (solo móvil)-->
        <div class="e wrapper">
          <!-- Sidebar Holder -->
          <nav id="sidebar" style="position: absolute; z-index: 9999; height: 100%; position: fixed;" class="hide">
             <!--DIV cuando está logueado-->
            <div class="sidebar-header no-login" v-if="!$root.isLogued()">
                <div class="boton" style="width: 100%; cursor: pointer;">
                <div @click="$root.login()">Iniciar sesión</div>
              </div>
              <div style="margin-top: 20px">Si inicias sesión podrás marcar los vídeos que más te gusten como favoritos para tenerlos siempre a mano.</div>

              <div v-if="0 == 1" style="margin-top: 20px">
                <router-link :to="{ path: '/suscribete'}">Suscríbete desde 0.99€ al mes</router-link>
              </div>
            </div>


                <!--DIV cuando está logueado-->
            <div class="sidebar-header si-login" style="padding: 0;" v-if="$root.isLogued()">
              <img :src="$store.state.session.banner" style="width: 100%">
              <div class="circuloWeb" :style="{ 'background-image': 'url(' + $store.state.session.avatar + ')'}" style="position: relative; top: -30px; left: 10px; background-size: cover;">
              </div>
              <p style="font-size: 18px; font-weight: bold; position: relative; top: -75px; left: 100px; max-width: 200px; width:fit-content"
                class="colortitulos">{{$store.state.session.name}}</p>
              <p style="font-size: 14px; position: relative; top: -95px; left: 103px; max-width: 200px; width:fit-content" class="colortitulos">@{{$store.state.session.user}}</p>
              <div class="row"
                style="font-size: 20px; font-weight: bold; margin-top: -100px; margin-left: 10px; margin-right: 10px; margin-bottom: 10px">
                <div class="col-6">
                  <p style="margin-bottom: -35px; font-size: 12px;">Vídeos</p> <br>
                  {{$store.state.session.videos}}
                </div>
                <div class="col-6">
                  <p style="margin-bottom: -35px; font-size: 12px;">Favoritos </p> <br>
                  {{$store.state.session.favoritos}}
                </div>
              </div>
            </div>
            <!-- <ul class="list-unstyled components" style="margin-bottom: 0">
              <li style=" margin-top: -5px; margin-bottom: -5px;" @click="$root.setPage('PageTutorial')">
                <a href="#" style="margin: 0; padding-left: 0; font-weight: normal; color: white;font-weight: bold">¿Cómo funciona?</a>
              </li>
            </ul> -->

            <ul class="list-unstyled components" style="margin-bottom: 0">
              <Tendencias/>
            </ul>

            <!--Este botón aparece solo si está logueado, si no está loguedo no aparece iniciar sesión, simplemente si intenta hacer algo que requiere loguin como darle a favorito pues le dirá que es necesario conectar con twitter y ya está-->
            <div class="row nopadding nomargin">
              <div class="col-12">
                <div v-if="0 == 1" style="margin-top: 20px">
                  <router-link :to="{ path: '/suscribete'}">Hazte premium por menos de 1 € al mes</router-link>
                </div>
                <div class="boton" v-if="$root.isLogued()" style="margin-top:20px;">
                  <div @click="$root.logout()" style="cursor:pointer;">Cerrar sesión</div>
                </div>
              </div>
            </div>

            <ul class="list-unstyled components" style="margin-bottom: 0; padding:0; margin-top:20px;margin-bottom:20px;">
              <li style=" margin-top: -5px; margin-bottom: -5px;">
                <div style="cursor:pointer; margin-left:20px;" @click="$root.setPage('PageCookies')">Cookies</div>
              </li>
              <li style=" margin-top: -5px; margin-bottom: -5px;padding-bottom:20px;">
                <div style="cursor:pointer;margin-top:10px; margin-left:20px;" @click="$root.setPage('PagePrivacidad')">Política de privacidad</div>
              </li>
            </ul>
          </nav>
        </div>

        <!--Botón flotante para añadir (solo móvil)-->
        <div class="btnFlotante" style="z-index: 999; bottom: 70px; background-color:#00a478" @click="showModalAddVideo">
          <img src="@/assets/images/addcliptter.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tendencias from "./Tendencias.vue"

import icon_inicio_seleccionado from '@/assets/images/home-seleccionado.png'
import icon_inicio from '@/assets/images/home.png'

import icon_descubre_seleccionado from '@/assets/images/descubre-seleccionado.png'
import icon_descubre from '@/assets/images/descubre.png'

import icon_favoritos_seleccionado from '@/assets/images/favoritos-seleccionado.png'
import icon_favoritos from '@/assets/images/favoritos.png'

import icon_secciones_seleccionado from '@/assets/images/secciones-seleccionado.png'
import icon_secciones from '@/assets/images/secciones.png'

import icon_tutorial_seleccionado from '@/assets/images/tutorial-seleccionado.png'
import icon_tutorial from '@/assets/images/tutorial.png'

export default {
    name: "MenuMovil",
    components: {
      Tendencias
    },
    data: () => {
        return {
          icon_inicio_seleccionado,
          icon_inicio,
          icon_descubre_seleccionado,
          icon_descubre,
          icon_favoritos_seleccionado,
          icon_favoritos,
          icon_secciones_seleccionado,
          icon_secciones,
          icon_tutorial_seleccionado,
          icon_tutorial
        }
    },
    methods: {
      showModalAddVideo() {
        if(!this.$root.isLogued()) {
          this.$root.showAlert_logueate()
          return
        }
        else {
          $("#vueModalAddVideo").modal({
            show: true,
            keyboard: false,
            backdrop: 'static'
          });
        }

      }

    },
    mounted: function () {
    }
}
</script>
