import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
// import VueAnalytics from 'vue-analytics'
import VueGtag from "vue-gtag";
import axios from "axios"
import routes from './routes';
import store from './store'
import PageMixin from './components/PageMixin'
import PageInicio from "./components/PageInicio.vue"
import PageDescubre from "./components/PageDescubre.vue"
import PageSecciones from "./components/PageSecciones.vue"
import PageTutorial from "./components/PageTutorial.vue"
import PageCliptter from "./components/PageCliptter.vue"
import PageCookies from "./components/PageCookies.vue"
import PagePrivacidad from "./components/PagePrivacidad.vue"
import PageSuscribete from "./components/PageSuscribete.vue"
var infiniteScroll =  require('vue-infinite-scroll')
import "select2"
import 'select2/dist/css/select2.css'
import 'bootstrap';
import "./sass/main.scss"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTimes, faShareAlt, faVideo, faCopy, faDonate } from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import VueNoty from 'vuejs-noty'

import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(Vue2TouchEvents)

Vue.use(VueNoty)

import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

library.add(faTimes, faShareAlt, faVideo, faTwitter, faCopy, faDonate)

Vue.config.productionTip = false;

const isProd = process.env.NODE_ENV === 'production'

Vue.use(VueRouter);
Vue.use(infiniteScroll)
Vue.component('font-awesome-icon', FontAwesomeIcon)

const router = new VueRouter({mode: 'history', routes});

router.beforeEach((to, from, next) => {
  if(from.name == null)
    window.previousUrl = null
  else
    window.previousUrl = from.path
    next()
})

if(isProd) {
  Vue.use(VueGtag, {
    config: { id: "UA-100041710-1" },
    appName: 'Cliptter',
    pageTrackerScreenviewEnabled: true
  }, router);
}

// Vue.use(VueAnalytics, {
//   id: 'UA-100041710-1',
//   router,
//   debug: {
//     enabled: !isProd,
//     sendHitTask: isProd
//   }
// })

Vue.directive('select2', {
    inserted(el) {
        $(el).on('select2:select', () => {
            const event = new Event('change', { bubbles: true, cancelable: true });
            el.dispatchEvent(event);
        });

        $(el).on('select2:unselect', () => {
            const event = new Event('change', {bubbles: true, cancelable: true})
            el.dispatchEvent(event)
        })
    },
});

new Vue({
    router,
    data: {
      show_loader: false,
      currentMenu: "pc",
      tipoVista: {
        tipo: '',
        cols: 'col-4',
        showInfoVideo: false,
      },
      currentPageName: '',
      currentPage: null,
      tipoVista: {
        tipo: 'galeria',
        cols: 'col-4',
        showInfoVideo: false
      },
      filter_seccion: {
        seccion: 'temas',
        buscar: ''
      },
      filter: {
        buscar: '',
        autor: {
          user: '',
          name: '',
          avatar: '',
          videos: 0,
          favoritos: 0,
          from: null
        },
        tema: {
          id_tema: null,
          tema: null,
          banner: '',
          avatar: '',
          videos: 0,
          favoritos: 0,
          from: null
        },
        name: null,
        duracion: 'todos',
        favoritos: false,
        populares: false
      },
      tweet_detail: {
        id_video: null,
        id_tweet:  null,
        imagen:  null,
        duracion:  null,
        url:  null,
        autor: null,
        input_enlace:  null,
        input_protagonista: null,
        input_descripcion: null,
        input_hashtags: null,
        input_temas: [],
        error: {
          code: null,
          message: null
        }
      },
      temas: [],
      tendencias: []
    },
    mixins: [PageMixin],
    store,
    mounted() {
      //Interceptamos las respuestas 401 para desloguear al usuario
      axios.interceptors.response.use(response => {
         return response;
      }, error => {
        if (error.response.status === 401) {
          this.$store.dispatch("setToken", null)
          this.$store.dispatch("setId_user", null)
          this.$store.dispatch("setPaypalToken", null)
          this.$store.dispatch("setUser", null)
          this.$store.dispatch("setName", null)
          this.$store.dispatch("setAvatar", null)
          this.$store.dispatch("setBanner", null)
          this.$store.dispatch("setAdmin", null)
          this.$store.dispatch("setVideos", null)
          this.$store.dispatch("setFavoritos", null)
          axios.defaults.headers.common = {}
          window.location.reload()
        }
        return error;
      });
    },
    render: h => h(App)
}).$mount('#app')
