<template>
    <div class="d-none d-md-block col-md-1 col-lg-2" style="border-right: 1px solid #38444d; padding-top: 10px" v-if="$root.currentMenu == 'pc'">
    <div style="position: sticky; top: 10px">
      <div class="opcion-menu"><img src="@/assets/images/logo.png" style="height: 30px" /></div>
      <div class="opcion-menu" :class="{'selected': $route.name == 'PageInicio'}"  @click="$root.setPage('PageInicio')">
        <img :src="$route.name == 'PageInicio' ? icon_inicio_seleccionado : icon_inicio" style="height: 30px;float: left;" />
        <div class="opcion-menu-texto ocultar-md">Inicio</div>
      </div>
      <div class="opcion-menu" :class="{'selected': $route.name == 'PageDescubre' || $route.name == 'PageAutor' || $route.name == 'PageTema'}" @click="$root.setPage('PageDescubre')">
        <img :src="($route.name == 'PageDescubre' || $route.name == 'PageAutor' || $route.name == 'PageTema') ? icon_descubre_seleccionado : icon_descubre" style="height: 30px;float: left;" />
        <div class="opcion-menu-texto ocultar-md">Descubre</div>
      </div>
      <div class="opcion-menu" :class="{'selected': $route.name == 'PageFavoritos'}" @click="$root.setPage('PageFavoritos')">
        <img :src="($route.name == 'PageFavoritos') ? icon_favoritos_seleccionado : icon_favoritos" style="height: 30px;float: left;" />
        <div class="opcion-menu-texto ocultar-md">Favoritos</div>
      </div>
      <div class="opcion-menu" :class="{'selected': $route.name == 'PageSecciones'}" @click="$root.setPage('PageSecciones')">
        <img :src="$route.name == 'PageSecciones' ? icon_secciones_seleccionado : icon_secciones" style="height: 30px;float: left;" />
        <div class="opcion-menu-texto ocultar-md">Secciones</div>
      </div>
      <div v-if="0 == 1" class="opcion-menu" :class="{'selected': $route.name == 'PageSuscribete'}" @click="$root.setPage('PageSuscribete')">
        <img :src="$route.name == 'PageSuscribete' ? icon_suscribete_seleccionado : icon_suscribete" style="height: 30px;float: left;" />
        <div class="opcion-menu-texto ocultar-md">Suscríbete</div>
      </div>
      <!-- <div class="opcion-menu" :class="{'selected': $route.name == 'PageTutorial'}" @click="$root.setPage('PageTutorial')">
        <img :src="$route.name == 'PageTutorial' ? icon_tutorial_seleccionado : icon_tutorial" style="height: 30px;float: left;" />
        <div class="opcion-menu-texto ocultar-md">Tutorial</div>
      </div> -->
      <div class="opcion-menu" v-if="!$root.isLogued()">
        <div @click="$root.login()">
          <img src="@/assets/images/usuario.png" style="height: 30px;float: left;" />
          <div class="opcion-menu-texto ocultar-md">Iniciar sesión</div>
        </div>
      </div>
      <div class="opcion-menu" v-else>
        <div @click="$root.logout()">
          <img src="@/assets/images/usuario.png" style="height: 30px;float: left;" />
          <div class="opcion-menu-texto ocultar-md">Cerrar sesión</div>
        </div>
      </div>
      <div class="opcion-menu">
        <img class="ocultar-imagen-boton" src="@/assets/images/addcliptter.png" style="height: 30px;float: left;"
          data-toggle="modal" data-target="#vueModalAddVideo" />
        <div class="boton ocultar-texto-boton" style="width: 100%; cursor: pointer;" @click="showModalAddVideo()">
          <div>Añadir</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import icon_inicio_seleccionado from '@/assets/images/home-seleccionado.png'
import icon_inicio from '@/assets/images/home.png'

import icon_descubre_seleccionado from '@/assets/images/descubre-seleccionado.png'
import icon_descubre from '@/assets/images/descubre.png'

import icon_favoritos_seleccionado from '@/assets/images/favoritos-seleccionado.png'
import icon_favoritos from '@/assets/images/favoritos.png'

import icon_secciones_seleccionado from '@/assets/images/secciones-seleccionado.png'
import icon_secciones from '@/assets/images/secciones.png'

import icon_suscribete_seleccionado from '@/assets/images/suscribete-seleccionado.png'
import icon_suscribete from '@/assets/images/suscribete.png'

import icon_tutorial_seleccionado from '@/assets/images/tutorial-seleccionado.png'
import icon_tutorial from '@/assets/images/tutorial.png'

export default {
    name: "MenuPc",
    data: () => {
        return {
          icon_inicio_seleccionado,
          icon_inicio,
          icon_descubre_seleccionado,
          icon_descubre,
          icon_favoritos_seleccionado,
          icon_favoritos,
          icon_secciones_seleccionado,
          icon_secciones,
          icon_tutorial_seleccionado,
          icon_tutorial,
          icon_suscribete_seleccionado,
          icon_suscribete
        }
    },
    methods: {

      showModalAddVideo() {
        if(!this.$root.isLogued()) {
          this.$root.showAlert_logueate()
          return
        }
        else {
          $("#vueModalAddVideo").modal({
            show: true,
            keyboard: false,
            backdrop: 'static'
          });
        }

      }
    },
    mounted: function () {

    }
}
</script>
