<template>
  <div id="vueModalNuevasCondiciones" class="modal fade" role="dialog" ref="vueModalNuevasCondiciones">
    <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content" style="background-color: #15202b">
        <div class="modal-header" style="color: white; border-bottom-color: #3a5061">
          <h4 class="modal-title">NOVEDADES</h4>
        </div>
        <div class="modal-body">
          <div style="color:#FFF;">
            A partir de ahora, !podrás compartir todos los videos donde quieras!
            <img src="/static/images/redes_sociales.png" style="width:100%;">
            <div style="text-align:center;">
            <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close">
              Cerrar
            </button>
          </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>



<script>

export default {
  name: "NuevasCondiciones",
  data: () => {
      return {
      }
  },
  components: {

  },
  destroyed() {

  },
  mounted: function () {
    $(this.$refs.vueModalNuevasCondiciones).on("hidden.bs.modal", this.closeModalNuevasCondiciones)
    if (this.$store.state.session.nuevas_condiciones == 0) {
      this.$nextTick(() => {
        $("#vueModalNuevasCondiciones").modal({
            show: true,
            keyboard: false,
            backdrop: 'static'
          });
      });
    }
  },
  methods: {
    closeModalNuevasCondiciones() {
      this.$store.dispatch("setNuevas_condiciones", 1)
    }

  },
 }
</script>
